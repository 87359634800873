import React from "react";
import HubspotContactForm from "./HubspotContactForm";

const BookDemoPage = ({ header, subheader, formId }) => {
  return (
    <>
      <section>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-10 md:pt-40 md:pb-20">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <div className="relative max-w-3xl pb-12 md:pb-20 text-center md:text-left">
                  <h1 className="h1 mb-4">{header}</h1>
                  <br/>
                  <p className="text-xl text-gray-400 font-semibold	">9 November, 1pm EST (5pm GMT) </p>

                  <br/>
                  <p className="text-xl text-gray-600">{subheader}</p>

                  <ul
                className="relative max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-600 dark:text-gray-400 mt-8 -mb-2"
                data-aos="fade-down"
                data-aos-delay="450"
              >
                <li className="flex items-center mb-2">
                  <span>
                    <span role="img" aria-label="spaceship">🚀</span> How you can scale annotation activity if you’re using open source medical image annotation tools
                  </span>
                </li>
                <li className="flex items-center mb-2">
                  <span><span role="img" aria-label="smile">🤠</span> How to tackle the challenges of getting FDA and CE certification when using open source tools</span>
                </li>
                <li className="flex items-center mb-2">
                  <span>
                    <span role="img" aria-label="light">⚡</span> How to get the most out of your annotation team when using decentralized annotation tools</span>
                </li>
              </ul>
                </div>
              </div>
              <HubspotContactForm
                region="eu1"
                portalId="25381551"
                formId={formId}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BookDemoPage;
