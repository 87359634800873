import React from "react";

import Layout from "../components/layout";
import BookWebinarPage from "../partials/BookWebinarPage";
import RightColours from "../utilities/RightColours";
import LeftColours from "../utilities/LeftColours";
import SEO from "../components/seo";

const TryItFree = ({ location }) => {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <BookWebinarPage
        header={
          "Lunch and Learn: How To Scale Your Medical Image Annotation Activity"
        }
        subheader={
          "In this 30 minute 'lunch and learn' session, Dr. Andreas Heindl, Machine Learning Product Specialist at Encord will look at how medical AI companies can scale their medical image annotation activity and accelerate model development. You'll learn:"
        }
        formId="721c9e51-8c69-48a8-9e08-bf3844fbbe9a"
      />
    </Layout>
  );
};

export default TryItFree;

export const Head = () => (
  <SEO
    title="Encord | Try it free"
    description="Get in touch with us to arrange a free trial of Encord, the active learning platform for computer vision, and experience how it can accelerate your AI model development."
  />
);
